
import { defineComponent, onMounted, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
const listData = ref();

export default defineComponent({
  name: "exam-general-detail",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      countDown: 0,
      countDownTime: "",
      examReady: false,
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
      router.go(0);
    }
    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getExam?id=" +
            route.params.id +
            "&examType=1",
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log("getExam");
          console.log(response.data.data.content[0]);
          listData.value = response.data.data.content[0];
          setCurrentPageBreadcrumbs( listData.value.title, []);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const register = () => {
      if (localStorage.getItem("id_token")) {
        localStorage.setItem("reloaded" , "1")
        router.push({
          name: "exam-general-learn",
          params: {
            id: route.params.id,
          },
        });
      } else {
        router.push({ name: "sign-in" });
      }
    };
    return {
      getDataList,
      listData,
      register,
    };
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    onBack(){
        this.$router.go(-1)
    }
  },
});
